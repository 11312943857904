import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AlegereaLocatiei from './AlegereaLocatiei';
import AlegereaSpecialitatii from './AlegereaSpecialitatii';
import AlegereaMedicului from './AlegereaMedicului';
import AlegereaConsultatiei from './AlegereaConsultatiei';
import Review from './Review';
import FinalizareaProgramarii from './FinalizareaProgramarii';
import axios from 'axios';
import AlegereInterval from './AlegereInterval';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import { URL } from './Config'
import { useSyncExternalStore } from 'react';

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


const steps = [1, 2, 3, 4, 5, 6, 7];

function CheckoutContent(props) {
  const [modal, setModal] = React.useState(false);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;


  var dateinterval = new Date();
  const [interval, setInterval] = React.useState([
    {
      startDate: new Date(),
      endDate: dateinterval.setDate(dateinterval.getDate() + 5),
      key: 'selection'
    }
  ]);

  const [intervalSelectat, setIntervalSelectat] = React.useState({ fromHour: null, toHour: null });
  const [intervaleAPI, setIntervaleAPI] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [location, setLocation] = React.useState("");
  const [locatii, setLocatii] = React.useState([]);
  const [specialization, setSpecialization] = React.useState("");
  const [consultation, setConsultation] = React.useState("");
  const [textModal, setTextModal] = React.useState('');
  const [medic, setMedic] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [nume, setNume] = React.useState(null);
  const [prenume, setPrenume] = React.useState(null);
  const [telefon, setTelefon] = React.useState(null);
  const [cnp, setCnp] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [numeTutore, setNumeTutore] = React.useState(null);
  const [prenumeTutore, setPrenumeTutore] = React.useState(null);
  const [telefonTutore, setTelefonTutore] = React.useState(null);
  const [observatii, setObservatii] = React.useState(null);
  const [eroare, setEroare] = React.useState(null)
  const [token, setToken] = React.useState(null)



  const changeDate = (data) => {
    setActiveStep(activeStep + 1);
    setDate(data.item)
    setMedic({
      id: data.id,
      nume: data.nume
    })
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (location === '') {
        setModal(true)
        setTextModal('Va rugam sa alegeti o locatie')
      } else {
        setTelefon(props.props.phoneNumberIvr)
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 1) {
      if (specialization === '') {
        setModal(true)
        setTextModal('Va rugam sa alegeti o specialitate')
      } else {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 2) {
      if (consultation === '') {
        setModal(true)
        setTextModal('Va rugam sa alegeti un tip de consultatie')
      } else {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 3) {

      setActiveStep(activeStep + 1);
    }
    if (activeStep === 4) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 5) {

      setActiveStep(activeStep + 1);
    }
    if (activeStep === 6) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", token);
      console.log(intervalSelectat)

      var ora_start= intervalSelectat.value.slice(0,5);
      var consultation_slots = allData.filter(obj => obj.location_id == location.id && obj.party_id == medic.id && obj.date == formatDate(date) && obj.start_time==ora_start+":00");

      console.log(consultation_slots[0])
      var raw = JSON.stringify({
        "applicant": {
          "surname" : nume,
          "given_name" : prenume,
          "calling_phone_number": telefon,
          "contact_email": email,
          "contact_date": formatDate(date),
          "contact_method":"ON-LINE",
          "is_patient": "NO"
        },
        "appointment":{
          "slot_1_id": consultation_slots[0].consultation_slot_id, // ????
          "first_slot_starts_at" : consultation_slots[0].date+" "+ consultation_slots[0].start_time,// ????
          "first_slot_ends_at": consultation_slots[0].date+" "+ consultation_slots[0].end_time,// ????
          "consultation_type_id": consultation.id,// ????
          "doctor_consultation_type_id": consultation_slots[0].doctor_consultation_type_id,// ????
          "doctor_schedule_id":  consultation_slots[0].doctor_schedule_id,// ????
          "note":observatii,
          "contact_date":formatDate(date),
          "contact_method": "ON-LINE",
          "calling_phone_number": telefon,
          "contact_email":email,
          "is_multi_slot": "NO",// ????
          "schedule_type": "TEMPLATE-BASED",// ????
          "appointment_source": "INOVO SCHEDULING",
          "appointment_link_id": "nu",
          "appointment_link_code": "nu",
          "status": "UNCONFIRMED"
        }
      });
     
      

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(URL + "/programare", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status=="OK") {
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow',
              mode: 'no-cors'
            };
            var myHeaders = new Headers();
            myHeaders.append("Access-Control-Allow-Origin", "true");
            console.log("TTTTTTTTTTTESTS")
            fetch(`https://app.2waysms.io/smsapi/index?key=463CFD147B059D&campaign=211&routeid=3&type=text&contacts=${telefon}&senderid=3861&msg=Confirmam programarea din data de ${formatDate(date)}, ora ${intervalSelectat.value}, la Cabinetul ${location.label} medic ${medic.nume} Va multumim si va dorim o zi frumoasa!`, requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));
            setActiveStep(activeStep + 1);
          } else {
            setEroare("Ceva nu a mers bine , va rugam incercati din nou")
          }
        })
        .catch(error => console.log('error', error));



    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  React.useEffect((props) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(URL + "/login", requestOptions)
      .then(response => response.json())
      .then(result => {

        setToken(result.sessionId)
        var myHeaders = new Headers();
        myHeaders.append("token", result.sessionId);

        requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(URL + "/lista_locatii", requestOptions)
          .then(response => response.json())
          .then(result => {

            result.forEach((data) => {
              var state = locatii;
              var obj = {
                value: data.location_name,
                label: data.location_name,
                id: data.location_id
              }
              state.push(obj);
              //console.log(state)
              setLocatii(state)
              //console.log(state)
            })
          })
          .catch(error => console.log('error', error));
      })
      .catch(error => console.log('error', error));

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };





  }, []);

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <PureModal
          isOpen={modal}
          onClose={() => {
            setModal(false);
            return true;
          }}
        >
          <span style={{ display: 'block', wordBreak: 'break-word', marginTop: 20, marginBottom: 20, paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>{textModal}</span>
        </PureModal>
        <Container component="main" >
          <Paper variant="outlined" >
            <Stepper activeStep={activeStep} alternativeLabel={true} orientation="horizontal">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {eroare == null ?
                    <div>
                      <Typography variant="h5" gutterBottom>
                        <center><div style={{ marginRight: 10, marginLeft: 10 }}>  Programarea a fost efectuata!</div></center>
                      </Typography>
                      <Typography variant="subtitle1">
                        <center><div style={{ marginRight: 10, marginLeft: 10 }}>
                          In scurt timp veti primi un SMS de confirmare a programarii. Va multumim !
                        </div></center>
                      </Typography>
                    </div>
                    : <div>{eroare}</div>}
                </React.Fragment>
              ) : (
                <React.Fragment>

                  {activeStep === 0 ? <AlegereaLocatiei
                    locatii={locatii}
                    setLocatii={setLocatii}
                    locatiaSelectata={location}
                    setLocation={setLocation}
                  /> : null}
                  {activeStep === 1 ?
                    <AlegereaSpecialitatii
                      token={token}
                      specialitateaSelectata={specialization}
                      setSpecialization={setSpecialization}


                    /> : null}
                  {activeStep === 2 ?

                    <AlegereaConsultatiei

                      specialitateaSelectata={specialization}
                      setSpecialization={setSpecialization}
                      consultatiaSelectata={consultation}
                      setConsultation={setConsultation}
                    />
                    : null}
                  {activeStep === 3 ?

                    <AlegereInterval
                      interval={interval}
                      setInterval={setInterval}

                    />
                    : null}
                  {activeStep === 4 ?

                    <AlegereaMedicului
                      consultationId={consultation.id}
                      interval={interval}
                      location={location}
                      specialization={specialization}
                      data={date}
                      medic={medic}
                      token={token}
                      setMedic={setMedic}
                      setIntervaleAPI={setIntervaleAPI}
                      changeDate={changeDate}
                      setAllData={setAllData}

                    />
                    : null}
                  {activeStep === 5 ?

                    <FinalizareaProgramarii
                      consultation={consultation}
                      location={location}
                      allData={allData}
                      data={date}
                      intervalSelectat={intervalSelectat}
                      setIntervalSelectat={setIntervalSelectat}
                      nume={nume}
                      medic={medic}
                      setNume={setNume}
                      prenume={prenume}
                      setPrenume={setPrenume}
                      telefon={telefon}
                      setTelefon={setTelefon}
                      cnp={cnp}
                      setCnp={setCnp}
                      email={email}
                      setEmail={setEmail}
                      numeTutore={numeTutore}
                      setNumeTutore={setNumeTutore}
                      prenumeTutore={prenumeTutore}
                      setPrenumeTutore={setPrenumeTutore}
                      telefonTutore={telefonTutore}
                      setTelefonTutore={setTelefonTutore}
                      observatii={observatii}
                      setObservatii={setObservatii}
                    />
                    : null}

                  {activeStep === 6 ?
                    <Review
                      consultation={consultation}
                      location={location}
                      medic={medic}
                      nume={nume}
                      date={date}
                      intervalSelectat={intervalSelectat}
                      prenume={prenume}
                      telefon={telefon}
                      cnp={cnp}
                      email={email}
                      numeTutore={numeTutore}
                      prenumeTutore={prenumeTutore}
                      telefonTutore={telefonTutore}
                      observatii={observatii}

                    />
                    : null}
                  {activeStep < 1 && (
                    <Button style={{ marginLeft: 15, marginTop: 15, marginBottom: 35 }}></Button>

                  )}

                  {activeStep >= 1 && (
                    <Button style={{ marginLeft: 15, marginTop: 15, marginBottom: 35 }} onClick={handleBack}>
                      Back
                    </Button>

                  )}
                  {activeStep != 4 ?
                    <div style={{ float: 'right' }}>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                        style={{ marginRight: 15, marginTop: 15, marginBottom: 15 }}
                      >
                        {activeStep === steps.length - 1 ? 'Confirma programarea' : 'Next'}
                      </Button>
                    </div>
                    : null}

                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Container>
      </React.Fragment>
    </div>
  );
}

export default function Checkout(props) {
  return <CheckoutContent props={props} />;
}
