import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}



export default function DateleClientului(props) {
    console.log(props)
    const [intervaleSelect, setIntervaleSelect] = React.useState(null);

    React.useEffect(() => {
        console.log(props)
        var consultation_slots = props.allData.filter(obj => obj.location_id == props.location.id && obj.party_id == props.medic.id && obj.date == formatDate(props.data))
        console.log(consultation_slots)
        var arr = [];

        consultation_slots.map((element, index) => {
            // setIntervaleSelect(element.locuri_libere)

            var obj = {
                key: index,
                value: element.start_time.slice(0, 5) + " - " + element.end_time.slice(0,5),
                label: element.start_time.slice(0, 5) + " - " + element.end_time.slice(0,5),
                formHour: element.start_time.slice(0, 5) + " - " + element.end_time.slice(0,5)
            }
            arr.push(obj);

        })
        console.log(arr)
        setIntervaleSelect(arr)

    }, [])

    return (
        <React.Fragment >
            <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                <center>
                    Finalizarea programarii la medicul <br /><b> {props.medic.nume}</b> in data  <br /> <b>{formatDate(props.data)}</b>
                </center>
            </Typography>
            <FormControl style={{ marginLeft: 15, width: '95%', marginBottom: 15 }}>
                <b><center style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>Alege Intervalul Orar</center></b>
                <Select value={props.intervalSelectat} options={intervaleSelect} placeholder="Alege Ora Consultului" onChange={(e) => { props.setIntervalSelectat(e) }} style={{ marginTop: 144 }} />
                <b><center style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>Si completeaza datele personale </center></b>
                <TextField value={props.nume} onChange={(e) => { props.setNume(e.target.value) }} id="standard-basic-nume" label="Nume " style={{ marginBottom: 10, marginTop: 10 }} />
                <TextField value={props.prenume} onChange={(e) => { props.setPrenume(e.target.value) }} id="standard-basic-prenume" label="Prenume" style={{ marginBottom: 10 }} />
                <TextField value={props.telefon} id="standard-basic-telefon" label="Telefon" style={{ marginBottom: 10 }} />
                <TextField value={props.email} onChange={(e) => { props.setEmail(e.target.value) }} id="standard-basic-email" label="E-mail" style={{ marginBottom: 10 }} />
                <TextField value={props.observatii} onChange={(e) => { props.setObservatii(e.target.value) }} id="standard-basic-observatii" label="Observatii" style={{ marginBottom: 10 }} />
            </FormControl>


        </React.Fragment>
    );
}
