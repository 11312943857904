import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import Select from 'react-select';
import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import PureModal from 'react-pure-modal';
import { URL } from './Config';
const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();


  return promiseInProgress &&

    <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Loader type="ThreeDots" color="#3f51b5" height="100" width="100" />
    </div>
};


export default function AlegereaSpecialitatii(props) {
  const [specialitati, setSpecialitati] = React.useState([]);
  const [loading, setIsLoading] = React.useState(false);

  React.useEffect(() => {

    var myHeaders = new Headers();
    myHeaders.append("token", props.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(URL + "/lista_specialitati", requestOptions)
      .then(response => response.json())
      .then(result => {
        var arr = [];
        result.map(element => {
          var obj = {
            key: element.speciality_name,
            value: element.speciality_name,
            label:element.speciality_name,
            id:element.speciality_id,
            consultation_types:element.consultation_types
          }
          arr.push(obj)
        })
        setSpecialitati(arr)
      })
      .catch(error => console.log('error', error));
  }, []);

  return (

    <div>
      <br></br>

      <Typography variant="h6" gutterBottom>
        <center> Alegerea specialitatii</center>
      </Typography>
      <LoadingIndicator />
{      console.log(specialitati)
}      <br></br>
      <br></br>
      {loading === true ? null :
        <FormControl style={{ marginLeft: 10, width: '95%', marginBottom: 15 }}>
          <Select options={specialitati} placeholder="Search" value={props.specialitateaSelectata} onChange={(e) => { props.setSpecialization(e) }} />
        </FormControl>}

    </div>

  );
}
