import React from 'react';
import Checkout from "./Checkout";
import qs from 'qs';
import { useJwt } from "react-jwt";


function App() {

  var token = qs.parse(window.location.search, { ignoreQueryPrefix: true }).token
  const { decodedToken, isExpired } = useJwt(token);

  return (
    <div>
      {console.log(decodedToken)}
      <div>
      {isExpired===false && decodedToken!=null ?
        <Checkout tokenApp={token} phoneNumberIvr={decodedToken.payload} />
      :<div>Tokenul a expirat , va rugam sa sunati din nou in Altermedico Medbot</div>}
      </div>

    </div>
  );
}

export default App;
