import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import MedicCard from './MedicCard';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import PureModal from 'react-pure-modal';
import { URL } from './Config';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress &&

        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="ThreeDots" color="#3f51b5" height="100" width="100" />
        </div>
};



function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function hasPartyId(array, partyId) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].medic === partyId) {
            return true;
        }
    }
    return false;
}

export default function AlegereaMedicului(props) {

    const [modal, setModal] = React.useState(false);
    const [intervale, setIntervale] = React.useState([]);
    const [loading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        setIsLoading(true)

        var startDate = new Date(props.interval[0].startDate);
        var reqstartDate = formatDate(startDate)
        var endDate = new Date(props.interval[0].endDate);
        var reqendDate = formatDate(endDate)
        //console.log(props.location)
        trackPromise(

            axios.get(
                URL + '/free_slots?location=' + props.location.id + "&date_start=" + reqstartDate + "&date_end=" + reqendDate + "&consultation_id=" + props.consultationId,
                {
                    headers: {
                        token: props.token
                    }
                }
            ).then((value) => {
                // console.log(value.data)
                var final_arr = [];
                if (value.data.length > 0) {
                    final_arr[0] = {
                        medic: value.data[0].party_id,
                        zile: [value.data[0].date]
                    }
                    value.data.map(element => {
                        if (hasPartyId(final_arr, element.party_id)) {
                            final_arr.map(element_final => {
                                if (!element_final.zile.includes(element.date) && element_final.medic==element.party_id) {
                                    element_final.zile.push(element.date)
                                }

                            })
                        } else {
                            final_arr.push({
                                medic: element.party_id,
                                zile: [element.date]
                            })
                        }

                    })
                }
                console.log(value.data)
                console.log(final_arr)

                props.setAllData(value.data)

                return final_arr

            }).then((resp) => {

                var myHeaders = new Headers();
                myHeaders.append("token", props.token);

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                };
             

                resp.map(element => {
                    fetch(URL + "/lista_doctori?doctor_id=" + element.medic, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            setIntervale(prevState =>[...prevState, {
                                medic: element.medic,
                                zile: element.zile,
                                nume_medic: result[0].doctor_name
                            }])

                        })
                        .catch(error => console.log('error', error));
                })
                setIsLoading(false)

            }))
            .catch(function (error) {
                if (error.response) {
                    //console.log(error.response.status);
                    if (error.response.status === 403) {
                        setModal(true)
                    }
                }
            })

    }, [])



    return (
        <React.Fragment>

            <Typography variant="h6" gutterBottom>
                <center>
                    <div style={{ marginBottom: 15 }}>
                        Alegerea medicului
                    </div>
                </center>
            </Typography>
            {loading === true ? <LoadingIndicator /> :
                <div>
                    {intervale.length == 0 && loading === false ? <div><center>Ne pare rau, nu exista medici disponibili in intervalul ales</center></div> :
                        <div>
                            {intervale.map((value, index) => {
                                return (<MedicCard key={index} nume={value.nume_medic} id={value.medic} zi={value.zile} date={props.date} changeDate={props.changeDate} setMedic={props.setMedic} />)
                            })}
                        </div>

                    }
                </div>}
        </React.Fragment>
    );
}
